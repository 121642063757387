import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "jquery"
import 'popper.js'
import "bootstrap/dist/js/bootstrap.bundle"
import "jquery.easing/jquery.easing"
import '@fortawesome/fontawesome-free/js/all'
import './shared/sb-admin-2'

Rails.start()
Turbolinks.start()
ActiveStorage.start()

window.toastr = require("toastr");
require("selectize");

console.log('Burnout Backoffice');
